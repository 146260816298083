const TabIndentation = {
  binding(element) {
    element.addEventListener("keydown", TabIndentation.onKeyDown);
    element.addEventListener("input", TabIndentation.onInput);
    return function dispose() {
      element.removeEventListener("keydown", TabIndentation.onKeyDown);
      element.addEventListener("input", TabIndentation.onInput);
    };
  },
  /**
   * Handles keydown events for indenting and outdenting lines in a textarea.
   * It triggers an 'input' event with types 'formatIndent' or 'formatOutdent'
   * based on whether the tab was pressed with the shift key.
   *
   * @param event - The keyboard event triggered when a key is pressed.
   */
  onKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      const inputEvent = new InputEvent("input", {
        inputType: event.shiftKey ? "formatOutdent" : "formatIndent",
        bubbles: true,
        cancelable: true
      });
      event.currentTarget.dispatchEvent(inputEvent);
    }
  },
  /**
   * Handles 'input' events specifically for processing 'formatIndent' and 'formatOutdent' input types.
   * Modifies the textarea's content based on the type of indentation required.
   *
   * @param e - The input event that was dispatched during indentation handling.
   */
  onInput(event) {
    if (event.inputType !== "formatIndent" && event.inputType !== "formatOutdent") {
      return;
    }
    event.preventDefault();
    const textarea = event.currentTarget;
    const { selectionStart, selectionEnd, value } = textarea;
    const tabSize = +getComputedStyle(textarea).tabSize;
    if (selectionStart !== selectionEnd) {
      const start = TabIndentation.getLineStart(value, selectionStart);
      let newSelectionStart = selectionStart;
      let newSelectionEnd = selectionEnd;
      let result = value.slice(start === 0 ? 0 : start + 1, selectionEnd).split("\n").map((line, index) => {
        const initialLength = line.length;
        const modifiedLine = event.inputType === "formatOutdent" ? TabIndentation.outdent(line, tabSize) : TabIndentation.indent(line);
        const lengthChange = modifiedLine.length - initialLength;
        if (index === 0) {
          newSelectionStart += lengthChange;
        }
        newSelectionEnd += lengthChange;
        return modifiedLine;
      }).join("\n");
      result = start === 0 ? result : `
${result}`;
      textarea.setRangeText(result, start, selectionEnd);
      textarea.setSelectionRange(newSelectionStart, newSelectionEnd);
    } else {
      if (event.inputType === "formatIndent") {
        textarea.setRangeText("	", selectionStart, selectionStart, "end");
      } else {
        const isNewLine = value[selectionStart] === "\n";
        const start = TabIndentation.getLineStart(
          value,
          // Skip the leading newline.
          isNewLine ? Math.max(0, selectionStart - 1) : selectionStart
        );
        let result = TabIndentation.outdent(value.slice(start, selectionEnd), tabSize);
        result = start === 0 ? result : `
${result}`;
        textarea.setRangeText(result, start, selectionEnd, "end");
      }
    }
  },
  outdent(source, tabSize) {
    const leadingWhitespace = TabIndentation.getLeadingWhitespace(source);
    if (leadingWhitespace.length === 0) return source;
    const segments = TabIndentation.getIndentationSegments(leadingWhitespace, tabSize);
    return source.replace(leadingWhitespace, segments.slice(0, -1).join(""));
  },
  indent(source) {
    const leadingWhitespace = TabIndentation.getLeadingWhitespace(source);
    return source.replace(leadingWhitespace, leadingWhitespace + "	");
  },
  getLeadingWhitespace(source) {
    var _a;
    return ((_a = source.match(/^\s*/)) == null ? void 0 : _a[0]) || "";
  },
  getLineStart(value, position) {
    while (position > 0 && value[position] !== "\n") {
      position--;
    }
    return position;
  },
  /**
   * Calculates the whitespace segments for a string of leading whitespace, merging certain segments for visual consistency.
   *
   * This function is designed to normalize the leading whitespace into consistent tab or space segments. It ensures that partial
   * tab-sized segments of spaces are merged into single tabs or combined to fit the defined tab size, aiding in consistent indentation handling.
   *
   * @param leadingWhitespace - The string of leading whitespace from a line of text.
   * @param tabSize - The number of spaces that constitute a tab segment.
   * @returns {string[]} - An array of strings, each representing a coherent segment of indentation.
   */
  getIndentationSegments(leadingWhitespace, tabSize) {
    const unmergedSegments = (leadingWhitespace.match(/(\t| +)/g) || []).flatMap((segment) => {
      if (segment === "	") {
        return [segment];
      }
      return Array.from(
        { length: Math.ceil(segment.length / tabSize) },
        (_, i) => segment.substr(i * tabSize, tabSize)
      );
    });
    const segments = [];
    for (let i = 0; i < unmergedSegments.length; i++) {
      const current = unmergedSegments[i];
      const next = unmergedSegments[i + 1];
      if (current === "	" || current.length >= tabSize || i === unmergedSegments.length - 1) {
        segments.push(current);
        continue;
      }
      segments.push(current + next);
      i++;
    }
    return segments;
  },
  /**
   * Formats the indentation of each line in a given source string using tabs.
   * It calculates the amount of leading whitespace in each line and replaces it with tabs based on the specified tab size.
   *
   * @param source - The string of text to format.
   * @param tabSize - The number of spaces that represent a single tabulation in the context of the source text.
   * @returns The source text with spaces replaced by tabs as per the calculated indentation levels.
   */
  format(source, tabSize) {
    return source.split("\n").map((line) => {
      const whitespace = TabIndentation.getLeadingWhitespace(line);
      const segments = TabIndentation.getIndentationSegments(whitespace, tabSize);
      return line.replace(whitespace, "	".repeat(segments.length));
    }).join("\n");
  }
};
export {
  TabIndentation
};
