let CDN = "https://esm.sh";
const CACHE = {
  theme: {},
  grammar: {}
};
function setCDN(cdn) {
  CDN = cdn;
}
function urlFromCDN(type, key) {
  if (typeof CDN === "function") {
    return CDN(type, key);
  }
  switch (type) {
    case "theme":
      return `${CDN}/tm-themes/themes/${key}.json`;
    case "grammar":
      return `${CDN}/tm-grammars/grammars/${key}.json`;
    case "oniguruma":
      return `${CDN}/vscode-oniguruma/release/onig.wasm`;
  }
}
async function fetchFromCDN(type, key) {
  if (key in CACHE[type]) {
    return CACHE[type][key];
  }
  const value = urlFromCDN(type, key);
  return CACHE[type][key] = typeof value !== "string" ? value : fetch(value).then((response) => response.ok ? response.json() : null).catch(console.error);
}
export {
  CDN,
  fetchFromCDN,
  setCDN,
  urlFromCDN
};
