// routes

export { Router } from './router/router.js'

// A

export { A } from './router/link.js'

// load

export { load } from './router/load.js'
